.Navbar {
    text-align: center;
}
/* 
Navbar Start
*/
h3 {
  color: white;
  padding: 0px 15px;
}
nav {
    background-color:red;
    overflow: hidden;
    display: flex;
    text-align: center;
    /* justify-content: center; */
    
    
  }
  
  
  nav a {
    font-size: 20px;
    color: white;
    padding: 0px 150px;
    text-decoration: none;
  }
  
  nav a:hover {
    background-color: #ddd;
    color: black;
  }
  
  li {
    display: inline;
    float: left;
    text-align: center;
    justify-content: center;
  }

  ul {
    text-align: center;
    justify-content: center;
    padding: 0;
    
  } 

  div.center-link {
    text-align: center;
    display: flex;
    margin: auto;
    justify-content: center;
    
  }





  /* 
  Navbar End
  */