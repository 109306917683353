img {
    display: flex;
    position: absolute;
    margin-left: 30%;
    margin-top: 1%;
}

.about-name {
    position: absolute;
    margin-left: 60%;
}

.about-me {
    display: flex;
    text-align: left;
    position: absolute;
    margin-left: 60%;
    margin-top: 5%;

}

.abm {
    color:  black;
}