/* Existing styles for sticky footer */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

p {
  color: white;
}



.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
 
  
 

  background-color: #353b53;
  color: white;
}
/* End of sticky footer styles */

/* Your existing footer styles */
.sb__footer-links {
  display: flex;
  gap: 20px;
}

.sb__footer-links-div {
  display: flex;
  flex-direction: column;
}

.sb__footer-links-div h4 {
  margin-bottom: 10px;
}

/* Three-column layout styles */
.footer-columns {
  display: flex;
  justify-content: space-between;
}

.footer-column {
  flex: 1;
  padding: 10px;
  text-align: center;
} 

/* Styles for the copyright section */
.copyright {
  text-align: center;
  padding: 20px;
  
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
